import { useEffect, useState } from "react";
import "./App.css";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { InView } from "react-intersection-observer";
import { ParallaxProvider } from "react-scroll-parallax";
// import banner from './building-corner-garden-open-day.jpg';

const SliderButton = ({ label, children }) => {
  const [open, setOpen] = useState(false);
  const props = useSpring({ height: open ? "auto" : 0, opacity: open ? 1 : 0 });
  const handleOpen = () => {
    window.plausible("Slider button", { props: { label } });
    setOpen(!open);
  };
  return (
    <div className="slider-button">
      <button onClick={handleOpen}>{label}</button>
      <animated.div style={props}>{children}</animated.div>
    </div>
  );
};

const SplitText = ({ children }) => {
  const words = children.split(" ");
  return (
    <>
      {words.map((word, i) => {
        const text = word.split("");
        return (
          <div>
            {text.map((char, index) => {
              const style = { animationDelay: 0 + index / 15 + "s" };
              if (index === 0) {
                return <span className="first-letter">{char}</span>;
              } else {
                return <span style={style}>{char}</span>;
              }
            })}
          </div>
        );
      })}
    </>
  );
};

const SlideInOnScroll = ({ children, className }) => (
  <InView threshold={0.5} triggerOnce={true}>
    {({ inView, ref, entry }) => (
      <div
        ref={ref}
        style={{
          opacity: inView ? 1 : 0,
          transform: inView
            ? "translate3d(0, 0, 0)"
            : "translate3d(0, 50px, 0)",
        }}
        className={className}
      >
        {children}
      </div>
    )}
  </InView>
);

function App() {
  const [headingText, setHeadingText] = useState("Institute Of Education");

  useEffect(() => {
    setTimeout(() => {
      setHeadingText("I O E");
    }, 3000);
    setTimeout(() => {
      setHeadingText("Interests Of Everyone");
    }, 3200);
    setTimeout(() => {
      setHeadingText("I O E");
    }, 6200);
    setTimeout(() => {
      setHeadingText("Improving Our Experience");
    }, 6400);
    setTimeout(() => {
      setHeadingText("I O E");
    }, 9400);
    setTimeout(() => {
      setHeadingText("Interests Of Everyone");
    }, 9600);
  }, []);

  return (
    <ParallaxProvider>
      <div className="App">
        <SlideInOnScroll className="slide-in-on-scroll">
          <div className="hero">
            <h1 id="title">Harriet Israel</h1>
            <h1>
              <SplitText>{headingText}</SplitText>
            </h1>
          </div>
        </SlideInOnScroll>

        <SlideInOnScroll className="content">
          <h2>
            Hi! 💐
            <br />
            Vote for me as Faculty Rep 2022/23!
          </h2>
          <p></p>
          <p>
            I have spent the last 3 years as an{" "}
            <strong>active representative</strong> for our student community.
            Last year, I was voted <strong>IOE Academic Rep of the Year</strong>
            !
          </p>
          <p>
            I have a great track record of securing{" "}
            <strong>positive changes for students</strong>.
          </p>
          <p>
            For me, no problem is too trivial or complex. I'm{" "}
            <strong>friendly, but tough</strong>. I happily take initiative in
            challenging situations.
          </p>
        </SlideInOnScroll>

        <SlideInOnScroll className="slide-in-on-scroll">
          <a
            className="hero-button"
            href="https://studentsunionucl.org/election/rep-elections-2022/institute-of-education-pgt-rep-0"
            target="_blank"
            rel="noreferrer"
          >
            Vote now!
          </a>
        </SlideInOnScroll>

        <SlideInOnScroll className="content">
          <h2>What can I help with?</h2>
          <SliderButton label="I feel isolated at IOE">
            <p>
              🤝 I will help co-create a community of staff and students,
              abolishing an “us” and “them” mentality.
            </p>
          </SliderButton>
          <SliderButton label="I have no idea what the Students Union is for">
            <p>
              🏛 I will foster a sense of belonging for students within the
              Students Union.
            </p>
          </SliderButton>
          <SliderButton label="I'm worried about handing work in on time">
            <p>
              ❣️ I will get IOE to guarantee a fair and clear extenuating
              circumstances policy.
            </p>
          </SliderButton>
          <SliderButton label="Why does it take so long to mark my essays?">
            <p>
              ✅ I will work with IOE to address the late release of assessment
              feedback.
            </p>
          </SliderButton>
          <SliderButton label="Food is getting so expensive!">
            <p>
              🍕 I will ensure that IOE offers free and accessible facilities
              for all students to heat food and get hot water – particularly
              crucial during the cost-of-living crisis.
            </p>
          </SliderButton>
        </SlideInOnScroll>

        {/* <ParallaxBanner
          layers={[{ image: banner, speed: -15 }]}
          className="aspect-[2/1]"
        /> */}

        <SlideInOnScroll className="content">
          <h2>Let's continue the conversation!</h2>
          <a
            className="twitter-button"
            href="https://twitter.com/hazlett92"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} /> Twitter
          </a>
        </SlideInOnScroll>

        <SlideInOnScroll className="slide-in-on-scroll">
          <a
            className="hero-button"
            href="https://studentsunionucl.org/election/rep-elections-2022/institute-of-education-pgt-rep-0"
            target="_blank"
            rel="noreferrer"
          >
            Vote for me!
          </a>
        </SlideInOnScroll>

        <div className="footer">
          <p>
            <FontAwesomeIcon icon={faCopyright} /> 2022 Harriet Israel.
          </p>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default App;
